import React from 'react';
import Testimonial from './testimonial';

export default () => (
  <section className="testimonials section">
		<div className="container">
			<div className="testimonials-inner section-inner">
				<div className="testimonials-header text-light">
					<h2 className="section-title mt-0">Working with Thomas</h2>
				</div>
				<div className="testimonials-wrap">
					<Testimonial
						span={2}
						content={(<p>Thomas cut down our page load times by a full 2 seconds across the board reducing our bounce rate by 30% and improving our google page speed score from 65% to 97%. Thomas made the process quick and easy. He rolled out changes to <a href="https://www.norcaladhd.com/">norcaladhd.com</a> in less than a day and saw immediate results.</p>)}
						name="Devon M."
					/>
					<Testimonial
						content={(<p>Thomas consulted and helped us plan out and make the right architectural decisions for <a href="https://tweaky.io/">Tweaky.io</a>. He’s a smart guy.</p>)}
						name="Aneesh S."
					/>
					<Testimonial
						content={(<p>Thomas is a great engineer, strong in any stack, and always weighs the pros and cons of situations to come up with the best solution for the greatest ROI. I'd definitely love to work with Thomas again in the future.</p>)}
						name="Joey A."
					/>
					<Testimonial
						span={2}
						content={(<p>He collaborated seamlessly across teams and partners, worked with enthusiasm and efficiency to improve our page load times, paving way for improved SEO and user acquisition. He fully and ingeniously understands user needs, collaborates well across teams, and delivers amazing results. He's a spectacular engineer, team-player and leader — I'd work with Thomas again in a heartbeat.</p>)}
						name="Arun J."
					/>
				</div>
			</div>
		</div>
	</section>
)