import React from 'react';

export default ({ span, content, name }) => {
  let testimonalClass = "testimonial text-sm is-revealing";
  
  if (span) {
    testimonalClass = testimonalClass.concat(` span-${span}`);
  }
  
  return (
    <div className={testimonalClass} style={{visibility: 'visible'}}>
      <div className="testimonial-inner">
        <div className="testimonial-main">
          <div className="testimonial-body">
            {content}
          </div>
        </div>
        <div className="testimonial-footer">
          <div className="testimonial-name">{name}</div>
        </div>
      </div>
    </div>
  )
}