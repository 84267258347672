import React from 'react';

export default () => (
  <section className="features section">
  		<div class="features-bg"><svg width="385" height="305" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="50%" y1="34.994%" x2="50%" y2="97.738%" id="footer-bg"><stop stop-color="#151616" offset="0%"></stop><stop stop-color="#222424" offset="100%"></stop></linearGradient></defs><path d="M384.557 116.012V305H0L210.643 0l173.914 116.012z" fill="url(#footer-bg)" fill-rule="evenodd"></path></svg></div>
		<div className="container">
			<div className="features-inner section-inner">
				<div className="features-header text-light">
					<h2 className="section-title mt-0">What I do</h2>
				</div>
				<div className="features-wrap">
					<div className="feature">
						<div className="feature-inner">
							<div className="feature-header mb-16">
								<div className="feature-icon mr-16">
									<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
										<g fillRule="nonzero" fill="none">
											<path d="M7 8H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM19 8h-6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" fill="#4353FF"/>
											<path d="M19 20h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" fill="#43F1FF"/>
											<path d="M31 8h-6a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" fill="#4353FF"/>
											<path d="M7 20H1a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" fill="#43F1FF"/>
											<path d="M7 32H1a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" fill="#4353FF"/>
											<path d="M29.5 18h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5z" fill="#43F1FF"/>
											<path d="M17.5 30h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM29.5 30h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5z" fill="#4353FF"/>
										</g>
									</svg>
								</div>
								<h4 className="feature-title m-0">Web Applications</h4>
							</div>
							<p className="text-sm mb-0">Scalable web software, built on stable technologies sets you up for success.</p>
						</div>
					</div>
					<div className="feature">
						<div className="feature-inner">
							<div className="feature-header mb-16">
								<div className="feature-icon mr-16">
									<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
										<g fillRule="nonzero" fill="none">
											<path d="M4 12H0V5a5.006 5.006 0 0 1 5-5h7v4H5a1 1 0 0 0-1 1v7z" fill="#43F1FF"/>
											<path d="M32 12h-4V5a1 1 0 0 0-1-1h-7V0h7a5.006 5.006 0 0 1 5 5v7zM12 32H5a5.006 5.006 0 0 1-5-5v-7h4v7a1 1 0 0 0 1 1h7v4z" fill="#4353FF"/>
											<path d="M27 32h-7v-4h7a1 1 0 0 0 1-1v-7h4v7a5.006 5.006 0 0 1-5 5z" fill="#43F1FF"/>
										</g>
									</svg>
								</div>
								<h4 className="feature-title m-0">Performance</h4>
							</div>
							<p className="text-sm mb-0">I have history with setting up performant websites, improving conversion, and getting what you need out of your website.</p>
						</div>
					</div>
					<div className="feature">
						<div className="feature-inner">
							<div className="feature-header mb-16">
								<div className="feature-icon mr-16">
								<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
										<g fillRule="nonzero" fill="none">
											<path d="M4 12H0V5a5.006 5.006 0 0 1 5-5h7v4H5a1 1 0 0 0-1 1v7z" fill="#43F1FF"/>
											<path d="M32 12h-4V5a1 1 0 0 0-1-1h-7V0h7a5.006 5.006 0 0 1 5 5v7zM12 32H5a5.006 5.006 0 0 1-5-5v-7h4v7a1 1 0 0 0 1 1h7v4z" fill="#4353FF"/>
											<path d="M27 32h-7v-4h7a1 1 0 0 0 1-1v-7h4v7a5.006 5.006 0 0 1-5 5z" fill="#43F1FF"/>
										</g>
									</svg>
								</div>
								<h4 className="feature-title m-0">Infrastructure as Code</h4>
							</div>
							<p className="text-sm mb-0">Easily managed and maintained software delivery solutions.</p>
						</div>
					</div>
					<div className="feature">
						<div className="feature-inner">
							<div className="feature-header mb-16">
								<div className="feature-icon mr-16">
									<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
										<g fill="none" fillRule="nonzero">
											<path d="M16 9c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4z" fill="#4353FF"/>
											<path d="M27 9c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4z" fill="#43F1FF"/>
											<path d="M27 12c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z" fill="#4353FF"/>
											<path d="M5 23c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z" fill="#43F1FF"/>
											<path d="M27 23c-1.859 0-3.41 1.28-3.858 3h-3.284A3.994 3.994 0 0 0 17 23.142v-3.284c1.72-.447 3-2 3-3.858 0-2.206-1.794-4-4-4-1.859 0-3.41 1.28-3.858 3H8.858A3.994 3.994 0 0 0 6 12.142V8.858c1.72-.447 3-2 3-3.858 0-2.206-1.794-4-4-4S1 2.794 1 5c0 1.858 1.28 3.41 3 3.858v3.284c-1.72.447-3 2-3 3.858 0 2.206 1.794 4 4 4 1.859 0 3.41-1.28 3.858-3h3.284A3.994 3.994 0 0 0 15 19.858v3.284c-1.72.447-3 2-3 3.858 0 2.206 1.794 4 4 4 1.859 0 3.41-1.28 3.858-3h3.284c.447 1.72 2 3 3.858 3 2.206 0 4-1.794 4-4s-1.794-4-4-4z" fill="#4353FF"/>
										</g>
									</svg>
								</div>
								<h4 className="feature-title m-0">Consulting</h4>
							</div>
							<p className="text-sm mb-0">Not sure what you need? I can help you figure that out too!</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)