import React from 'react';
import { Link, graphql } from 'gatsby';

// import Bio from '../components/bio';
// import Layout from '../components/layout';
import SEO from '../components/seo';
// import { rhythm } from '../utils/typography';

import '../scss/style.scss';
import main from '../utils/main';

import Header from '../components/header';
import Hero from '../components/hero';
import Testimonials from '../components/testimonials';
import Features from '../components/features';
import Footer from '../components/footer';

class BlogIndex extends React.Component {
  componentDidMount() {
    main();
  }
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <div className="body-wrap boxed-container">
        {/* <Header /> */}
        <main>
          <Hero />
          <Features />
          <Testimonials />
        </main>
        <Footer />
      </div>
      // <Layout location={this.props.location} title={siteTitle}>
      //   <SEO
      //     title="All posts"
      //     keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      //   />
      //   <Bio />
      //   {posts.map(({ node }) => {
      //     const title = node.frontmatter.title || node.fields.slug;
      //     return (
      //       <div key={node.fields.slug}>
      //         <h3
      //           style={{
      //             marginBottom: rhythm(1 / 4),
      //           }}
      //         >
      //           <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
      //             {title}
      //           </Link>
      //         </h3>
      //         <small>{node.frontmatter.date}</small>
      //         <p
      //           dangerouslySetInnerHTML={{
      //             __html: node.frontmatter.description || node.excerpt,
      //           }}
      //         />
      //       </div>
      //     );
      //   })}
      // </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
